<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text"><router-link to="/">Pro Track</router-link> / Accounts</h1>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <v-layout row wrap class="d-flex justify-space-between align-center">
              <v-flex xs8 md6 lg4>
                  <v-text-field single-line outlined label="Type to search.." prepend-inner-icon="mdi-magnify" v-model="searchText"></v-text-field>
              </v-flex>
              <v-flex xs4 md4 lg4 class="d-flex justify-end">
                  <v-icon class="mx-2" @click="setMenuStyle('grid')" :class="getMenuClass('grid')">mdi-apps</v-icon>
                  <v-icon class="mx-2 mr-5" @click="setMenuStyle('list')" :class="getMenuClass('list')">mdi-menu</v-icon>
                  <v-btn class="primary white--text" rounded @click="addAccount"><v-icon>mdi-plus</v-icon>Add Account</v-btn>
              </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="menu == 'list'">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase primary--text">#</th>
                  <th class="text-left uppercase primary--text">Name</th>
                  <th class="text-left uppercase primary--text">Phone</th>
                  <th class="text-left uppercase primary--text">Email</th>
                  <th class="text-left uppercase primary--text">Department</th>
                  <th class="text-left uppercase primary--text">Role</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in filteredUsers" :key="item._id">
                  <td>{{ index+ 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td v-if="item.department">{{ item.department.name }}</td><td v-else></td>
                  <td class="caption">{{ item.role }}</td>
                  <td><v-btn  small class="primary black--text" @click="viewAccount(item._id)"><v-icon>mdi-magnify</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
        <v-flex xs12 v-else>
          <v-layout row wrap>
            <v-flex xs12 md3 lg2  class="pa-2" v-for="item in filteredUsers" :key="item._id" style="cursor: pointer;">
              <div class="pa-2" style="background-color: #212121;height: 100%;" @click="viewAccount(item._id)">
                <img :src="item.display_picture" v-if="item.display_picture" width="100%"/>
                <div style="padding-top: 100%;" class="grey mb-1" v-else></div>
                <h3>{{ item.name }}</h3>
                <h4 class="primary--text" v-if="item.department">{{ item.department.name }}</h4>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config";
  export default {
    data() {
      return {
        users: [],
        searchText: "",
        menu : "grid"
      };
    },
    mounted() {
      this.fetchUsers();
    },
    computed: {
      filteredUsers() {
        const query = this.searchText.toLowerCase();
        if (query.length >= 3) {
          return this.users.filter((item) =>
            JSON.stringify(item).toLowerCase().includes(query)
          );
        } else {
          return this.users;
        }
      },
    },
    methods: {
      async fetchUsers() {
        let url = BASE_URL + "/user";
        let { data } = await Axios.get(url);
        this.users = data; 
      },
      viewAccount(id) {
        this.$router.push({ name: "ViewAccount", params: { id: id } });
      },
      addAccount(){
        this.$router.push('/add-account')
      },
      setMenuStyle(menu){
        this.menu = menu
      },
      getMenuClass(menu){
        if(this.menu == menu){
          return 'primary--text'
        }
      }
    },
  };
  </script>
  
  
  